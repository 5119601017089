.cp-circles {
  height: auto;
  margin-top: -1px;
  overflow: hidden;

  @include teq-breakpoint(0, $large) {
    padding-bottom: $margin*4;
    padding-top: $margin*8;
  }

  @include teq-breakpoint($large) {
    height: calc((var(--vh, 1vh) * 100) *3.5);
  }

  .pin-circles {
    height: auto;

    @include teq-breakpoint($large) {
      height: calc((var(--vh, 1vh) * 100));
    }

    >.teq-row {
      @include teq-breakpoint($large) {
        width: 100%;
      }
    }
  }

  .circles {
    @include teq-breakpoint(0,$large) {
      align-content: center;
      flex-wrap: wrap;
      height: auto;
    }

    //height: calc(100vh - 60px);

    .hoverCircle {
      aspect-ratio: 1/1;
      background-color: #f64fc8;
      border-radius: 50%;
      content: "";
      display: block;
      filter: blur(20px);
      height: calc(100% + 0px);
      left: 40%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      z-index: -1;

      @include teq-breakpoint(0,$large) {

      }
    }

    .circles-item {
      aspect-ratio: 1/1;
      position: relative;
      width: 20%;

      @include teq-breakpoint(0,$large) {
        margin: 15px;
        width: calc((var(--vh, 1vh) * 30));
      }

      span {
        border: 1px solid white;
        border-radius: 50%;
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;

        @include transition(borderColor);
      }

      >p {
        margin-bottom: 0;
        word-break: break-all;
        z-index: 3;
      }

      /* &:first-child {
        &:hover {
          ~ .hoverCircle {
            left: 40%;
          }
        }
      } */
    }
  }
}
