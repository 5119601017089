.cp-hero-banner {
  background-color: white;
  height: calc((var(--vh, 1vh) * 100) *2);
  margin-bottom: 0;

  @include teq-breakpoint($large) {
    height: calc((var(--vh, 1vh) * 100) *4);
  }

  >.teq-row {
    height: 100%;
    position: relative;

    .pin-hero {
      background-color: white;
      height: calc((var(--vh, 1vh) * 100));
      width: 100vw;
    }
  }

  .jump-logo {
    #id_1 {
      width: 82%;
    }

    #id_3 {
      width: 81%;
    }

    svg {
      mix-blend-mode: difference;
      opacity: 0;
      transform: translateY(40px);
      width: 100%;


      path {
        fill: white;
      }


      /*
      path {
        fill: transparent;
        stroke: #737373;
      } */
    }
  }

  .circle-anim {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;

    @include teq-breakpoint(0,$large) {
      bottom: 10%;
      height: auto;
      left: 20%;
    }

    .ellipse {
      background-color: black;
      border-radius: 100%;
      bottom: -10%;
      content: '';
      display: block;
      overflow: hidden;
      padding-bottom: 53%;
      position: absolute;
      right: 16%;
      transform: rotate(-10deg);
      width: 30%;

      @include teq-breakpoint(0,$large) {
        padding-bottom: 160%;
        right: 0%;
        width: 100%;
      }

      .ellipse-inner {
        border-radius: 100%;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        span {
          background-color: map-get($colors, c-pink);
          border-radius: 50%;
          bottom: -20%;
          content: '';
          display: block;
          filter: blur(40px);
          padding-bottom: 13vw;
          position: absolute;
          right: -10%;
          width: 13vw;

          @include teq-breakpoint(0,$large) {
            bottom: -10%;
            filter: blur(3vw);
            padding-bottom: 26vw;
            width: 26vw;
          }
        }
      }
    }
  }
}
