@font-face {
  font-family: "Marcus";
  font-style: normal;
  font-weight: 400;
  src: url("#{$fonts-url}/marcus/MarcusTraianus-Bold.woff2") format("woff2"), url("#{$fonts-url}/marcus/MarcusTraianus-Bold.woff") format("woff"), url("#{$fonts-url}/marcus/MarcusTraianus-Bold.otf") format("opentype");
}


@font-face {
  font-family: "Roobert-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("#{$fonts-url}/roobert/Roobert-Regular.woff2") format("woff2"), url("#{$fonts-url}/roobert/Roobert-Regular.woff") format("woff");
}


$f-sizes:((68,32), (52,28), (46, 25), (30, 24), (24, 18), (22, 18), (20, 18), (16, 16),(12, 12), (11, 12));
$f-weights: 700, 600, 500, 400;


@mixin labeur {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

@mixin f-size($f-size-desktop, $f-size-mobile: $f-size-desktop) {
  font-size: #{$f-size-mobile}px;

  // @if $f-size-mobile <= 24 {
  line-height: #{$f-size-mobile + 6}px;
  // }

  // @else {
  //   line-height: 77%;
  // }

  @include teq-breakpoint($large) {
    font-size: #{$f-size-desktop}px;

    // @if $f-size-desktop <= 24 {
    line-height: #{$f-size-desktop + 6}px;
    // }

    // @else {
    //   line-height: 77%;
    // }
  }
}

.roobert {
  font-family: "Roobert-Regular";
}

.marcus {
  font-family: "Marcus";
}

@mixin f-weight($f-weight) {
  font-weight: $f-weight;
}

// FONT SIZES
@each $f-size-desktop, $f-size-mobile in $f-sizes {
  .txt-#{$f-size-desktop} {
    @include f-size($f-size-desktop, $f-size-mobile);
  }
}
// FONT WEIGHT
@each $f-weight in $f-weights {
  .w-#{$f-weight} {
    @include f-weight($f-weight);
  }
}


p {
  margin-bottom: 16px;
}


// ALIGN
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// CASE
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}
