.cp-gallery {
  height: calc((var(--vh, 1vh) * 100) *15);
  margin-top: -30vh;
  position: relative;
  z-index: 5;



  >.teq-row {
    width: calc(100% + 60px);
  }

  .cp-gallery-inner {
    height: calc((var(--vh, 1vh) * 100));
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .gallery-item {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    .gallery-item-inner {
      height: calc((var(--vh, 1vh) * 60));
      opacity: 0;
      transform: scale(0.2);
      width: auto;

      p {
        text-align: right;
      }

      .img {
        height: 100%;
        overflow: hidden;
        width: auto;
      }

      img {
        @include transition(transform, 2s);

        height: 100%;
      }

      a {
        &::after {
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        pointer-events: all;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }


    &:nth-child(even) {
      .gallery-item-inner {
        margin-left: 10vw;
        transform-origin: right center;

        @include teq-breakpoint($large) {
          margin-left: 30vw;
        }
      }

      img {
        p {
          text-align: left;
        }
      }
    }

    &:nth-child(odd) {
      .gallery-item-inner {
        margin-right: 10vw;
        text-align: right;
        transform-origin: left center;

        @include teq-breakpoint($large) {
          margin-right: 30vw;
        }
      }
    }

    $iChildren : 12;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        z-index: $iChildren - $i;
      }
    }
  }
}
