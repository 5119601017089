@use "sass:map";

.btn-nav {
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  color: white;
  font-family: "Marcus";
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0 0 0;
  text-transform: uppercase;

  &:hover {
    color: map-get($colors, c-pink);
  }
}

.btn-action {
  background-color: #212121;
  border-radius: 10px;
  cursor: pointer;
  padding: 16px 20px 12px;
  right: 45px;
  top: 30px;

  @include transition();

  svg {
    @include transition();
  }

  &:hover {
    transform: scale(0.8);

    svg {
      transform: scale(1.2);
    }
  }
}
