.teq-container {
  display: block;
  margin: $teq-container-margin;
  padding: 0 $padding-container-mobile;
  width: 100%;

  @include teq-breakpoint($large) {
    margin: $teq-container-margin-lg;
    padding: 0 $padding-container;
  }

  &.bg {
    padding: $teq-container-margin-bg;

    @include teq-breakpoint($large) {
      padding: $teq-container-margin-bg-lg;
    }
  }
}
