$margin: 10px;
$max-w: 1600px;
$max-w-sm: 900px;

$breaks : (
  'sm' : $small,
  'md' : $medium,
  'lg': $large,
  'tab': $tablet,
  'xl': $small-screen
);
