@use "sass:map";

$colors: (
  c-white: #fff,
  c-black: #000,
  c-dark : #212121,
  c-pink: #f64fc8,
  c-lightblue: #eef7fa,
  c-blue: #04a5e3,
  blue-bigstone: #0f1e29,
  petrol: #004666,
  petrol-80: #335f7d,
  petrol-60: #67879d,
  petrol-l: #ddecf1,
  petrol-l-30: #ddecf1,
  grey: #4c5356,
  grey-2: #0e2b3a,
  c-silver: #a8adb3,
  silver-30: #e5e6e8,
  silver-50: #d3d6d9,
  radial-black: radial-gradient(63.75% 77.26% at 85.49% 3.19%, #004666 24.48%, #000 100%),
);


// COLORS CLASS
@each $name, $color in $colors {
  .#{$name} {
    color: $color;
  }
}

// BACKGROUND COLORS CLASS
@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color;
  }
}

// FILL COLOR FOR SVG
@each $name, $color in $colors {
  .fill-#{$name} {
    fill: $color;

    path {
      fill: $color;
    }
  }
}

@mixin pastille($color: map-get($colors,g-one)) {
  &::before {
    background: $color;
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 17px;
    padding-bottom: 10px;
    width: 10px;
  }
}


a {
  color: map-get($colors, c-black);

  &:hover {
    color: map-get($colors, petrol);
  }
}
