.max-w {
  margin: 0 auto;
  padding: $max-w-padding;
  width: auto;

  @include teq-breakpoint($large) {
    max-width: $max-w;
    padding: $max-w-padding-lg;
  }
}


.max-w-small {
  margin: 0 auto;
  max-width: $max-w-sm;
}
