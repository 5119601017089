.cp-faq {
  margin-top: -1px;
  overflow: hidden;

  .titles-faq {
    p {
      font-size: clamp(40px, 7.8vw, 7.8vw);
      line-height: 1;
      margin-bottom: 0;
      position: relative;

      span {
        display: inline-block;
        position: relative;
      }

      &:first-child {
        left: 0;
        text-align: left;

        span {

        }
      }

      &:nth-child(2) {
        right: 0;
        text-align: right;

        span {

        }
      }
    }
  }

  .accordion {
    margin: 15px;
  }

  .accordion-menu {
    border-top: 1px solid white;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: $margin*3 $margin*4 $margin*3 0;
    position: relative;
    user-select: none;
  }

  .accordion-content {
    font-size: 20px;
    height: 0;
    line-height: 1.3;
    overflow: hidden;
  }

  .accordion-content.expanded {
    height: 0;
    overflow: hidden;
  }

  .accordion-plus,
  .accordion-minus {
    align-items: center;
    aspect-ratio: 1/1;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
  }

  p {
    margin: $margin 0 $margin*3;
  }
}
