@use "sass:map";

footer#footer {
  background: map.get($colors, c-dark);
  color: white;
  height: auto;
  margin-bottom: 0;
  margin-top: -1px;
  padding-bottom: $margin*6;
  padding-top: $margin*6;
  position: relative;
  text-align: center;
  z-index: 101;

  @include teq-breakpoint(0,$large) {
    overflow: hidden;

    >.teq-row {
      >.col-12 {
        flex-wrap: wrap;

        span {
          margin-bottom: 20px;
          width: 100%;

          &.m-left-4 {
            display: none;
          }
        }
      }
    }
  }
}
