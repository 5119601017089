#nft {
  background-color: #000;
  color: white;
  display: none;
  height: 100vh;
  max-width: 1400px;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: 102;

  .color-nft {
    color: var(--nft);
  }

  .fill-nft {
    fill: var(--nft);
  }

  .nft-container {
    margin: 0;
    padding: 15px;

    @include teq-breakpoint($large) {
      padding: 40px 55px;
    }
  }

  .left {
    @include teq-breakpoint(0,$large) {
      order: 2;
    }

    .video-container {
      height: calc(100vh - 80px);

      video {
        object-fit: contain;
      }

      img {
        left: 50%;
        object-fit: cover;
        object-position: center;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .player {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
          cursor: pointer;

          @include transition();

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &.playing {
        .cover-video,
        .player {
          display: none !important;
        }
      }
    }
  }

  .right {
    @include teq-breakpoint($large) {
      height: calc(100vh - 80px);
      position: sticky;
      top: 40px;
    }

    @include teq-breakpoint(0,$large) {
      margin-bottom: 40px;
      order: 1;
    }

    .right-infos {
      height: auto;
      overflow: auto;
      padding-right: $margin;

      @include teq-breakpoint(0,$large) {
        margin-bottom: 40px;
      }

      @include teq-breakpoint($large) {
        height: calc(100vh - 140px);
      }
    }

    svg {
      width: 100%;
    }

    .nav-b {
      background-color: #212121;
      border-radius: 10px;
      gap: 10px;
      margin-top: auto;
      padding: $margin $margin*2 $margin $margin;

      .open-nft-menu,
      .close-nft {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .close-nft {
    right: 0;
    top: 0;
  }

  #buyNft.minted {
    opacity: 0.3;
    pointer-events: none;
  }
}


#root {
  background-color: transparent;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 110;

  .App {
    background-color: white;
    border-radius: 10px;
    height: auto;
    left: 50%;
    max-width: 600px;
    opacity: 0;
    padding: $margin*4;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    width: calc(100% - 30px);

    .actions__item {
      >div {
        @include transition();
      }

      &:hover {
        cursor: pointer;
      }
    }

    .error-message {
      flex-wrap: wrap;

      >span {
        width: 100%;

        &:not(:first-child) {
          padding-top: 0;
        }

        &:not(:last-child) {
          padding-bottom: 0;
        }
      }
    }
  }

  .test {
    >div {
      &:first-child {
        left: 10px;
        position: absolute;
        top: 10px;
      }
    }

    .cross {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
