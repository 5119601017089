.cp-oeuvre {
  height: calc((var(--vh, 1vh) * 100) *4);
  margin-top: -2px;
  z-index: 5;

  >.teq-row {
    @include teq-breakpoint(0,$large) {
      width: 100vw;
    }

    .cp-oeuvre-pin {
      height: calc((var(--vh, 1vh) * 100));
      width: 100%;
    }
  }

  .left {
    position: relative;
    z-index: 2;
  }

  .intro_logo {
    svg {
      max-width: 750px;
      width: 100%;

      @include teq-breakpoint(0,$large) {
        margin-left: 30px;
        margin-top: 30px;
        max-width: calc(100% - 60px);
        width: 100%;
      }
    }
  }

  .right {
    height: calc((var(--vh, 1vh) * 100));
    position: relative;
    z-index: 1;

    .video-container {
      align-items: center;
      display: flex;
      height: calc((var(--vh, 1vh) * 100));
      pointer-events: none;

      @include teq-breakpoint(0,$large) {
        height: calc((var(--vh, 1vh) * 70));
      }
    }

    img,
    video {
      height: 100%;
      max-height: 450px;
      width: 100%;
    }
  }
}
