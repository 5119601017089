.cp-readingblock {
  margin-top: -1px;
  z-index: 2;

  @include teq-breakpoint($large) {
    padding-top: 40px;
  }

  .text {
    p {
      font-size: clamp(30px, 6.3vw, 78px);
      line-height: 1.3;
    }

    .letter {
      opacity: 0.1;
    }
  }

  &#cp3 {
    padding-top: $margin*10;
    position: relative;
    z-index: 6;

    @include teq-breakpoint($large) {
      padding-top: $margin*20;
    }

    &::before {
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0) 90%);
      content: '';
      display: block;
      height: calc((var(--vh, 1vh) * 100));
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(calc(-100% + 10px));
      width: 100%;
    }

    .text {
      p {
        font-size: clamp(30px, 4vw, 90px);
        line-height: 1.3;
      }
    }
  }
}
