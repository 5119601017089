$nfts : '#97FAF3',
  '#D9FDB9',
  '#FDB4CA',
  '#DDD0CC',
  '#82F2FC',
  '#CFB4FC',
  '#B1B22A',
  '#8696FD',
  '#E43337',
  '#E8B860',
  '#C6FAC1',
  '#FCB8D1';

#header {
  color: white;
  left: 0;
  margin: 0;
  mix-blend-mode: difference;
  position: absolute;
  top: 0;
  z-index: 102;

  @include teq-breakpoint(0,$large) {
    display: none;
  }

  a {
    color: white;
  }
}

#menu_scroll {
  background-color: map-get($colors, c-dark);
  border-radius: 10px;
  left: 10px;
  max-width: 838px;
  padding: $margin $margin $margin $margin;
  position: fixed;
  top: 10px;
  width: calc(100% - 20px);
  z-index: 102;

  @include teq-breakpoint($large) {
    bottom: 0;
    left: 50%;
    padding: $margin $margin*4;
    top: auto;
    transform: translate(-50%, 100%);
    width: 80%;
  }



  svg {
    width: 28px;
  }

  a {
    color: white;
    text-transform: uppercase;
  }

  .btn-mobile {
    display: none;
  }

  @include teq-breakpoint(0,$large) {
    .btn-mobile {
      align-items: center;
      display: flex;

      svg {
        height: 24px;
        pointer-events: none;
        width: auto;
      }
    }

    ul {
      background-color: map-get($colors, c-dark);
      border-radius: 10px;
      flex-wrap: wrap;
      left: 0;
      padding: $margin $margin*2 $margin $margin;
      position: absolute;
      top: 50px;
      transform: translateY(calc(-100% - 60px));
      width: 100%;

      @include transition();

      li {
        margin-bottom: 20px;
        text-align: center;
        width: 100%;

        a {
          font-size: 30px;
          line-height: 34px;
        }
      }

      &.opened {
        transform: translateY(0);
      }
    }
  }
}


#menu_nfts {
  background-color: map-get($colors, c-black);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 105;

  >ul {
    left: 50%;
    padding: $margin*4;
    top: 50%;
    transform: translate(-50%, -50%);

    >li {
      margin-bottom: 3vw;

      a {
        position: relative;

        img {
          left: 50%;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%) scale(0.4);
          width: 10vw;
          z-index: -1;

          @include transition(all,1s);
        }
      }

      @each $n in $nfts {
        &[data-color="#{$n}"] {
          a:hover {
            color: #{$n};

            img {
              opacity: 1;
              transform: translate(-50%, -50%) scale(1);
            }
          }
        }
      }
    }
  }

  .close-menu_nfts {
    bottom: 30px;
    left: 50%;
    right: auto;
    top: auto;
    transform: translateX(-50%);
  }


  a {
    color: white;
    font-size: 4.5vw;
    line-height: 1.1;
  }
}
