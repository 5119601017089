@mixin transition($type:all,$time:0.36s,$ease:cubic-bezier(0.25, 1, 0.5, 1)) {
  -webkit-transition: $type $time $ease;
  -moz-transition: $type $time $ease;
  -ms-transition: $type $time $ease;
  -o-transition: $type $time $ease;
  transition: $type $time $ease;
}

.anim-img-scale {
  overflow: hidden;

  img {
    transform: scale(1.3);
  }
}


.anim-img-reveal-bottom,
.anim-img-reveal-bottom-delay {
  overflow: hidden;
  position: relative;

  img {
    @include transition(all,3s);

    margin-top: 1px;
    //transform: scale(1.3) translate3d(-0.0001rem, 0, 0.000001rem);
  }

  .overlay {
    background-color: black;
    content: '';
    display: block;
    height: 100%;
    left: -2px;
    position: absolute;
    top: -2px;
    transform-origin: center top;
    width: calc(100% + 4px);
    z-index: 1;

    @include transition(height,1.3s);
  }

  /*
    &.show {
      img {
        transform: scale(1) translate3d(-0.0001rem, 0, 0.000001rem);
      }
    } */
}


.anim-to-left {
  opacity: 0;
  transform: translateX(40px);
}


.anim-to-top {
  opacity: 0;
  transform: translateY(40px);
}


.anim-txt-to-top {
  overflow: hidden;

  >.child {
    display: block;
    opacity: 0;
    transform: translateY(100%);
  }
}


.anim-txt-to-left {
  overflow: hidden;

  >.child {
    display: block;
    opacity: 0;
    transform: translateX(40px);
  }
}
