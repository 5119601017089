$breaks : ('sm' : $small, 'md' : $medium, 'lg': $large ,'tab': $tablet, 'xl': $small-screen);

$gutter: $margin*3;
$margin-container: $margin*10;

// 1 - TEQ-CONTAINER

/* Margin whithout color background */
$teq-container-margin: #{$margin*4} auto;
$teq-container-margin-lg: #{$margin*8} auto;

/* Margin whith color background */
$teq-container-margin-bg: #{$margin*4} $gutter;
$teq-container-margin-bg-lg: #{$margin*8} $gutter;

/* Padding Teq-container */
$padding-container: $gutter;
$padding-container-mobile: #{$gutter / 2};



// 2 - MAX-W
$max-w-padding: 0 20px;
$max-w-padding-lg: 0 $margin-container - $gutter;



// 3 - TEQ-ROW

/* Row */
$teq-row-gutter: -#{$padding-container};
$teq-row-gutter-mobile: -#{$padding-container-mobile};


/* Row inside a col */
$teq-row-gutter-for-col: -#{$gutter / 2};



// 4 - COL
$col-padding: 0 15px;
