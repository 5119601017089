$justify: (
  center: center,
  stretch: stretch,
  start: start,
  end: end,
  between: space-between,
  around: space-around,
  evenly: space-evenly,
);

@mixin justify-breakpoints($a, $k) {
  @each $key,$val  in $breaks {
    @include teq-breakpoint($val) {
      .justify-#{$key}-#{$a} {
        justify-content: $k;
      }
    }
  }
}

@each $name, $value in $justify {
  .justify-#{$name} {
    justify-content: $value;
  }
}
