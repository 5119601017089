#about {
  background-color: black;
  border-left: 1px solid white;
  color: white;
  height: 100vh;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 100%;

  @include teq-breakpoint(0,$large) {
    padding-bottom: 80px;
    padding-top: 40px;
  }

  .about-inner {
    @include teq-breakpoint($large) {
      overflow: hidden;
    }
  }

  .sections {
    height: 100%;
    width: 100vw;

    @include teq-breakpoint($large) {
      overflow: hidden;
    }

    .sections-inner {
      width: 100%;

      @include teq-breakpoint(0,$large) {
        flex-wrap: wrap;
      }

      @include teq-breakpoint($large) {
        height: 100vh;
        width: fit-content;
      }
    }

    .section {
      color: white;
      min-width: 80vw;

      &.section-0 {
        min-width: 100%;
        padding: 15px;
        width: 100%;

        @include teq-breakpoint(0,$large) {
          height: auto !important;
        }

        @include teq-breakpoint($large) {
          min-width: auto;
          padding: 15px;
          width: 50px;
        }

        svg {
          width: 20px;

          @include teq-breakpoint($large) {
            width: 50px;
          }
        }

        /* &:hover {
          cursor: pointer;

          svg {
            path {
              fill: white !important;
            }
          }
        } */
      }

      &.section-2 {
        >div {
          >div {
            @include teq-breakpoint(0,$large) {
              >div {
                flex-wrap: wrap;

                img {
                  height: auto !important;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &.section-2,
      &.section-3 {
        @include teq-breakpoint($large) {
          max-width: 100vw;
          width: fit-content;
        }
      }


      &.section-3, {
        @include teq-breakpoint($large) {
          margin-left: 100px;
        }

        img {
          max-width: 500px;
        }

        @include teq-breakpoint(0,$large) {
          margin-top: 80px;

          >div {
            flex-wrap: wrap;
          }
        }
      }


      &.section-4 {
        @include teq-breakpoint($large) {
          margin-left: 10vw;
          min-width: auto;

          .title {
            span {
              margin-left: 80px;
            }
          }
        }

        .txt {
          @include teq-breakpoint($large) {
            margin-left: 100px;
            max-width: 600px;
            min-width: 50vw;
          }
        }

        @include teq-breakpoint(0,$large) {
          margin-top: 80px;

          >div {
            flex-wrap: wrap;

            >div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .section-1 {
    .title {
      font-size: clamp(40px, 6vw, 120px);
      line-height: 1.2;
    }
  }
}

.close-about {
  display: none;

  @include teq-breakpoint(0,$large) {
    bottom: 20px;
    right: 20px;
    top: auto;
  }
}

html.opened {
  //overflow: hidden;

  body {
    //overflow: hidden;
  }

  #about {
    transform: translateX(0%);
  }

  #smooth-wrapper {
    transform: translateX(-100%);
  }

  .close-about {
    display: flex;
  }

  #menu_scroll {
    @include teq-breakpoint($large) {
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}


#smooth-wrapper,
#about {
  @include transition(transform,1s);
}



#tertiodecimo_opere {
  color: white;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  #background-video {
    bottom: 0;
    height: 100vh;
    left: 0;
    object-fit: cover;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
  }

  >div {
    background-color: black;
    opacity: 0;
    padding: 100px;

    .video-c {
      height: 50vh;
      width: 100%;

      .video {
        height: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 2;
      }
    }
  }

  .content-c {
    position: relative;
    z-index: 2;
  }
}
