$margins :  'top','bottom','left','right';

@mixin margins-breakpoints($m,$i) {
  @each $key,$val  in $breaks {
    @include teq-breakpoint($val) {
      .m-#{$m}-#{$key}-#{$i} {
        margin-#{$m}: calc(#{$margin} * #{$i});
      }
    }
  }
}

@each $m in $margins {
  @for $i from 0 through 12 {
    .m-#{$m}-#{$i} {
      margin-#{$m}: calc(#{$margin} * #{$i});
    }

    @include margins-breakpoints($m, $i);
  }
}

@each $m in $margins {
  @for $i from 0 through 12 {
    @include margins-breakpoints($m, $i);
  }
}
