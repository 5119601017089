.d-block {
  display: block;
}

.d-flex {
  align-items: flex-start;
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-small-wrap {
  @include teq-breakpoint(0, $large) {
    flex-wrap: wrap;
  }
}

.desktop-only {
  display: none;

  @include teq-breakpoint($large) {
    display: flex;
  }
}

.mobile-only {
  display: flex;

  @include teq-breakpoint($large) {
    display: none;
  }
}
