$padding :  'top','bottom','left','right';

@mixin padding-breakpoints($m,$i) {
  @each $key,$val  in $breaks {
    @include teq-breakpoint($val) {
      .p-#{$key}-#{$m}-#{$i}, div[class^=col-].p-#{$key}-#{$m}-#{$i} {
        padding-#{$m}: calc(#{$margin} * #{$i});
      }
    }
  }
}

@each $m in $padding {
  @for $i from 0 through 12 {
    .p-#{$m}-#{$i}, div[class^=col-].p-#{$m}-#{$i} {
      padding-#{$m}: calc(#{$margin} * #{$i});
    }
  }

  @for $i from 0 through 12 {
    @include padding-breakpoints($m, $i);
  }
}

@mixin p-inline-breakpoints() {
  @each $key,$val  in $breaks {
    @include teq-breakpoint($max-width: $val) {
      .p-#{$key}-inline-none {
        padding-inline: 0;
      }
    }
  }
}

@include p-inline-breakpoints();

.p-inline-none {
  padding-inline: 0 !important;
}

.p-right-none {
  padding-right: 0 !important;
}

.p-left-none {
  padding-left: 0 !important;
}
