/* ==========================================================================
    Main styles
   ========================================================================== */
// CONFIGURATION
@charset "UTF-8";

// Tequilarapido CSS Library
@import "_teq-library/teq-library";
@import "_vars/paths";
@import "_vars/colors";
@import "_vars/fonts";
@import "_vars/anims";
@import "_vars/button";
@import "global";

// LIBRARIES / PLUGINS
@import "vendor/swiper";

// PROJECT


// COMPONENTS
@import "components/global/footer";
@import "components/global/header";
@import "components/cp-hero-banner";
@import "components/cp-readingblock";
@import "components/cp-circles";
@import "components/cp-oeuvre";
@import "components/cp-gallery";
@import "components/cp-faq";
@import "components/about";
@import "nft";
