@use "sass:map";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


html,
body {
  font-family: "Roobert-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;

  @include teq-breakpoint(0,$large) {
    scroll-behavior: smooth;
    width: 100vw;
  }

  @include labeur;
}


.main {
  display: flex;
  flex-wrap: wrap;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-decoration: none;
}


.noise {
  height: 100%;
  left: 0;
  opacity: 0.08;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}




/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($colors, c-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($colors, c-pink);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($colors, c-pink);
}
