.col {
  flex: 1 0 0%;
  padding: $col-padding;
}

div[class^="col-"],
ul[class^="col-"],
li[class^="col-"],
.col,
.teq-row > * {
  padding: $col-padding;

  > .teq-row {
    /* stylelint-disable */
    margin-right: $teq-row-gutter-for-col;
    margin-left: $teq-row-gutter-for-col;
    /* stylelint-enable */
  }
}

@mixin col-breakpoints($i) {
  @each $key, $val in $breaks {
    @include teq-breakpoint($val) {
      .col-#{$key}-#{$i} {
        flex: 0 0 (8.33333333% * $i);
        max-width: (8.33333333% * $i);
      }
      .offset-#{$key}-#{$i} {
        margin-left: (8.33333333% * $i);
      }
    }
  }
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 (8.33333333% * $i);
    max-width: (8.33333333% * $i);
    width: (8.33333333% * $i);
  }
  .offset-#{$i} {
    margin-left: (8.33333333% * $i);
  }
}

@for $i from 1 through 12 {
  @include col-breakpoints($i);
}
